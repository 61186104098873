import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const trackableRoutes = [
      '/',
      '/category/:category_name/:subcategory_name/:category_id/:subcategory_id',
      '/announcement/:id',
      '/profile',
      '/sign',
      '/adform',
      '/promote',
    ];

    const currentPath = location.pathname;

    if (trackableRoutes.some(route => currentPath.match(new RegExp(route)))) {
      if (analytics) {
        logEvent(analytics, 'screen_view', {
          page_path: currentPath,
          page_title: document.title,
        });
      }
    }
  }, [location]);
};

export default usePageTracking;