import { useState, useEffect } from "react";
import { getUserAdd } from "../../../api/getuserad";
import { getCategories } from "../../../api/getCategories";

import { t } from "i18next";

import { AdvertisementsUI } from "../comui/AdvertisementsUI";

const statusMapping = {
    "Активные": "active",
    "Ожидающие": "pending",
    "Неактивные": "inactive",
    "Отклоненные": "rejected"
};

function Advertisements({ setActiveTab }) {
    const [filter, setFilter] = useState("Активные");
    const [adsData, setAdsData] = useState([]);
    const [filteredAdsData, setFilteredAdsData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [counts, setCounts] = useState({
        active: 0,
        pending: 0,
        inactive: 0,
        rejected: 0,
    });
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [sort, setSort] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getCategories();
                const categoryOptions = data.map(category => ({
                    label: category.name,
                    value: category.id,
                    subcategories: category.subcategories,
                }));
                setCategories(categoryOptions);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchCounts = async () => {
            setLoading(true);
            try {
                const statuses = Object.values(statusMapping);
                const countsData = {};
                for (const status of statuses) {
                    const data = await getUserAdd(status);
                    countsData[status] = data.items.length;
                }
                setCounts(countsData);
            } catch (error) {
                console.log('Ошибка загрузки данных');
            } finally {
                setLoading(false);
            }
        };

        fetchCounts();
    }, []);

    useEffect(() => {
        const fetchAds = async () => {
            setLoading(true);
            try {
                const status = statusMapping[filter];
                const data = await getUserAdd(status);
                setAdsData(data.items);
                setFilteredAdsData(data.items);
            } catch (error) {
                console.log('Ошибка загрузки данных');
            } finally {
                setLoading(false);
            }
        };

        fetchAds();
    }, [filter]);

    const handleBackClick = () => {
        setActiveTab(null);
    };

    const handleSortChange = (selectedOption) => {
        let sortedData = [...filteredAdsData];

        switch (selectedOption) {
            case t('dateDesSort'):
                sortedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                break;
            case t('dateAscSort'):
                sortedData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                break;
            case t('priceAscSort'):
                sortedData.sort((a, b) => a.price - b.price);
                break;
            case t('priceDesSort'):
                sortedData.sort((a, b) => b.price - a.price);
                break;
            default:
                break;
        }

        setFilteredAdsData(sortedData);
        setSort(selectedOption);
    };

    const handleSearchChange = (event) => {
        const text = event.target.value.toLowerCase();
        setSearchText(text);

        const filteredData = adsData.filter(ad =>
            ad.title.toLowerCase().includes(text) ||
            ad.description.toLowerCase().includes(text)
        );

        setFilteredAdsData(filteredData);
    };

    const getNoUserAdText = () => {
        switch (filter) {
            case "Активные":
                return {
                    headerText: t('activeAdsHeader'),
                    contentText: t('activeAdsContent'),
                };
            case "Ожидающие":
                return {
                    headerText: t('pendingAdsHeader'),
                    contentText: t('pendingAdsContent'),
                };
            case "Неактивные":
                return {
                    headerText: t('inactiveAdsHeader'),
                    contentText: t('inactiveAdsContent'),
                };
            case "Отклоненные":
                return {
                    headerText: t('rejectedAdsHeader'),
                    contentText: t('rejectedAdsContent'),
                };
            default:
                return {
                    headerText: t('defaultAdsHeader'),
                    contentText: t('defaultAdsContent'),
                };
        }
    };

    return (
        AdvertisementsUI(handleBackClick, filter, setFilter, counts, searchText, handleSearchChange, categories, sort, handleSortChange, loading, filteredAdsData, getNoUserAdText)
    );
}

export default Advertisements;