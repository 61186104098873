import React from "react";
import "../style/SearchBar.css";
import searchIcon from "../../../assets/icons/search.svg";
import Dropdown from "../../../common/components/Dropdown";
import { t } from "i18next";

function SearchBar({ onSearch, onSort }) {
    const sortOptions = [
        "По умолчанию",
        "По дате (сначала новые)",
        "По дате (сначала старые)",
        "По цене (сначала дешевые)",
        "По цене (сначала дорогие)"
    ];

    const handleInputChange = (e) => {
        onSearch(e.target.value);
    };

    const handleSortChange = (selectedOption) => {
        let sortValue = "";
        if (selectedOption === "По дате (сначала новые)") sortValue = "date_newest";
        else if (selectedOption === "По дате (сначала старые)") sortValue = "date_oldest";
        else if (selectedOption === "По цене (сначала дешевые)") sortValue = "price_low";
        else if (selectedOption === "По цене (сначала дорогие)") sortValue = "price_high";

        onSort(sortValue);
    };

    return (
        <div className="search_sort_container">
            <div className="input_container">
                <img
                    className="search_icon_input"
                    src={searchIcon}
                    alt="search_icon"
                />
                <input
                    className="second_section_search"
                    placeholder={t('searchPlaceholder')}
                    onChange={handleInputChange}
                />
            </div>
            <Dropdown
                text="Сортировать по"
                options={sortOptions}
                selected="По умолчанию"
                onOptionSelect={handleSortChange}
                width={"s340"}
            />
        </div>
    );
}

export default SearchBar;