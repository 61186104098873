import React, { useState } from "react";
import "../style/dropdown.css";

import arrow from "../../assets/icons/up.svg";
import done from "../../assets/icons/check.svg";

const DropdownCategory = ({ text, options, selected, width }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selected);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleCategoryClick = (option) => {
        setSelectedOption(option.label);
        setSubcategories(option.subcategories || []);
        setSelectedSubcategory(null);
        setIsOpen(true);
    };

    const handleSubcategoryClick = (subcategory) => {
        setSelectedSubcategory(subcategory.name);
        setIsOpen(false);
    };

    return (
        <div className="dropdown_container">
            <div className={`dropdown_header ${width}`} onClick={toggleDropdown}>
                <span style={{ width: "max-content" }}>
                    {selectedSubcategory || selectedOption || text}
                </span>
                <img
                    src={arrow}
                    alt="toggle_arrow"
                    className={`dropdown_arrow ${isOpen ? "" : "rotate"}`}
                />
            </div>
            {isOpen && (
                <div className="dropdown_menu">
                    {!selectedOption && (
                        <ul className="dropdown_list">
                            {options.map((option, index) => (
                                <li
                                    key={index}
                                    className={`dropdown_item ${option.label === selectedOption ? "selected" : ""}`}
                                    onClick={() => handleCategoryClick(option)}
                                >
                                    <span>{option.label}</span>
                                    {option.label === selectedOption && (
                                        <img src={done} alt="selected_icon" className="done_icon" />
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                    {selectedOption && subcategories.length > 0 && (
                        <ul className="dropdown_list">
                            {subcategories.map((subcategory, index) => (
                                <li
                                    key={index}
                                    className={`dropdown_item ${subcategory === selectedSubcategory ? "selected" : ""}`}
                                    onClick={() => handleSubcategoryClick(subcategory)}
                                >
                                    <span>{subcategory.name}</span>
                                    {subcategory === selectedSubcategory && (
                                        <img src={done} alt="selected_icon" className="done_icon" />
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default DropdownCategory;