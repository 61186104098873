import React from 'react';
import { t } from 'i18next';
import searchIcon from '../../../assets/icons/search.svg';
import Dropdown from '../../../common/components/Dropdown';
import DropdownCategory from "../../../common/components/DropdownCategory";

export function AdControlContent(searchText, handleSearchChange, categories, sort, handleSortChange) {
    return <div className="controls_content">
        <div className="input_container">
            <img className='search_icon_input' src={searchIcon} alt='search_icon' />
            <input
                className="second_section_search"
                placeholder={t('searchPlaceholder')}
                value={searchText}
                onChange={handleSearchChange} />
        </div>
        <div className="controls">
            <DropdownCategory
                text={t('category')}
                options={categories}
                selected={null}
                width={"s256"} />
            <Dropdown
                text={t('sortBy')}
                options={[
                    t('dateDesSort'),
                    t('dateAscSort'),
                    t('priceAscSort'),
                    t('priceDesSort')
                ]}
                selected={sort || t('defaultSort')}
                onOptionSelect={handleSortChange}
                width={"s256"} />
        </div>
    </div>;
}