import React from "react";
import { t } from "i18next";

export function AdFilterTabButtons(filter, setFilter, counts) {
    return <div className="filters">
        <button
            className={filter === "Активные" ? "active" : ""}
            onClick={() => setFilter("Активные")}
        >
            {t('active')} <span className="chat_filter_count">{counts.active || 0}</span>
        </button>
        <button
            className={filter === "Ожидающие" ? "active" : ""}
            onClick={() => setFilter("Ожидающие")}
        >
            {t('pending')} <span className="chat_filter_count">{counts.pending || 0}</span>
        </button>
        <button
            className={filter === "Неактивные" ? "active" : ""}
            onClick={() => setFilter("Неактивные")}
        >
            {t('inactive')} <span className="chat_filter_count">{counts.inactive || 0}</span>
        </button>
        <button
            className={filter === "Отклоненные" ? "active" : ""}
            onClick={() => setFilter("Отклоненные")}
        >
            {t('rejected')} <span className="chat_filter_count">{counts.rejected || 0}</span>
        </button>
    </div>;
}