import React, { useState } from 'react';

import Category from '../components/Category';
import Fooder from '../components/Fooder';
import Header from '../components/Header';
import MobileSection from '../components/Mobile';
import Second from '../components/Second';
import VipAdvert from '../components/VipAdvert';
// import Advert from '../components/Advert';
import MobileBottomBar from '../components/MobileBottomBar';

// import NewsModal from '../../modals/news/ui/Main';

import { I18nextProvider } from 'react-i18next';
import i18n from '../../../language/i18n';

import usePageTracking from '../../../features/analytics/usePageTracking';

function Main() {
    usePageTracking();
    const [bottomBarHidden, setBottomBarHidden] = useState(false);

    const handleBottomBarToggle = (isHidden) => {
        setBottomBarHidden(isHidden);
    };

    return (
        <I18nextProvider i18n={i18n}>
            <Header onToggleMenu={handleBottomBarToggle} />
            <Second />
            <Category />
            <MobileSection />
            <VipAdvert />
            {/* <Advert /> */}
            <Fooder />
            {!bottomBarHidden && <MobileBottomBar activeTabName={'home'} />}
            {/* <NewsModal menuVisible={true}/> */}
        </I18nextProvider>
    );
}

export default Main;