import React, { useState } from "react";
import "../style/dropdown.css";

import arrow from "../../assets/icons/up.svg";
import done from "../../assets/icons/check.svg";

const Dropdown = ({ text, options, selected, width, onOptionSelect }) => {
    const [is_open, set_is_open] = useState(false);
    const [selected_option, set_selected_option] = useState(selected);

    const toggle_dropdown = () => set_is_open(!is_open);

    const handle_option_click = (option) => {
        set_selected_option(option);
        set_is_open(false);
        if (onOptionSelect) {
            onOptionSelect(option);
        }
    };

    return (
        <div className="dropdown_container">
            <div className={`dropdown_header ${width}`} onClick={toggle_dropdown}>
                <span style={{ width: "max-content" }}>{selected_option || text}</span>
                <img
                    src={arrow}
                    alt="toggle_arrow"
                    className={`dropdown_arrow ${is_open ? "" : "rotate"}`}
                />
            </div>
            {is_open && (
                <>
                    <div
                        className="click navigation_content_background_white"
                        onClick={() => set_is_open(false)}
                    ></div>
                    <ul className="dropdown_list">
                        {options.map((option, index) => (
                            <li
                                key={index}
                                className={`dropdown_item ${option === selected_option ? "selected" : ""}`}
                                onClick={() => handle_option_click(option)}
                            >
                                <span>{option}</span>
                                {option === selected_option && (
                                    <img src={done} alt="selected_icon" className="done_icon" />
                                )}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

export default Dropdown;