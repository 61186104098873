import React, { useState, useEffect, useCallback } from 'react';
import '../style/Header.css';

import MenuModal from '../../navigation/ui/Main';
import UserModal from '../../modals/user_modal/ui/Main';
import SearchModal from '../../modals/search_modal/ui/Main';
import LanguageModal from '../../modals/language_modal/ui/Main';

import app_icon from '../../../app_icon.svg';
import chat_icon from '../../../assets/icons/chat.svg';
import heart_icon from '../../../assets/icons/heart.svg';
import user_icon from '../../../assets/icons/user.svg';

import Dropdown from '../../../common/components/Dropdown';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../language/i18n';

import Icon from '../../../common/components/Icon';

function Header({ onToggleMenu = () => { } }) {
    const [menuVisibility, setMenuVisibility] = useState({
        menu: false,
        user: false,
        address: false,
        search: false,
        language: false,
    });

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [selectedCountry, setSelectedCountry] = useState('kazakhstan');
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('language') || 'kz');

    const countryTranslation = {
        kazakhstan: t('kazakhstan'),
        russia: t('russian'),
        china: t('china'),
    };

    useEffect(() => {
        i18n.changeLanguage(currentLanguage);
    }, [currentLanguage, i18n]);

    const changeLanguage = (lng) => {
        setCurrentLanguage(lng);
        localStorage.setItem('language', lng);
        i18n.changeLanguage(lng);
    };

    const toggleMenu = useCallback(
        (menuName) => {
            if (menuName === 'user') {
                const token = localStorage.getItem('access_token');
                if (!token) {
                    navigate('/sign');
                    return;
                }
            }
            setMenuVisibility((prev) => {
                const newMenuState = !prev[menuName];
                if (menuName === 'menu') {
                    onToggleMenu(newMenuState);
                }
                return {
                    ...prev,
                    [menuName]: newMenuState,
                };
            });
        },
        [navigate, onToggleMenu]
    );

    return (
        <header className="header">
            <div className="header_left">
                <div className="header_logo">
                    <Link to="/">
                        <img src={app_icon} alt="Agroland Logo" />
                    </Link>
                </div>
                <div className="click mobile_none border_background_100" onClick={() => toggleMenu('menu')}>
                    <Icon src="bar" alt="bar" />
                </div>
                <div className="mobile_none">
                    <Dropdown
                        text={countryTranslation[selectedCountry]}
                        options={Object.values(countryTranslation)}
                        selected={countryTranslation[selectedCountry]}
                        onOptionSelect={(country) => {
                            const countryKey = Object.keys(countryTranslation).find(
                                (key) => countryTranslation[key] === country
                            );
                            setSelectedCountry(countryKey);
                        }}
                        width={"s280"}
                    />
                </div>
            </div>
            <div className="header_actions">
                <div className="header_language_selector" onClick={() => toggleMenu('language')}>
                    <Icon src="glob" alt="change_language" />
                    <span className="click language_select">{t('language')}</span>
                </div>
                <button className="click border_background_100 header_icon_button" onClick={() => toggleMenu('search')}>
                    <Icon src="search" alt="search" />
                </button>
                <div className="click mobile_back mobile_menu border_background_100" onClick={() => toggleMenu('menu')}>
                    <Icon
                        className={menuVisibility.menu ? '' : 'white_icon'}
                        src={menuVisibility.menu ? 'rounded_close' : 'bar'}
                        alt={menuVisibility.menu ? 'close' : 'menu'}
                    />
                </div>
                <button className="click mobile_none border_background_100 header_icon_button">
                    <Link to="/profile#messages">
                        <img src={chat_icon} alt="chat_icon" />
                    </Link>
                </button>
                <button className="click mobile_none border_background_100 header_icon_button">
                    <Link to="/profile#favorites">
                        <img src={heart_icon} alt="heart_icon" />
                    </Link>
                </button>
                <button className="mobile_none header_create_ad_button">
                    <Link className="text_white" to="/adform">
                        {t('createAnnouncement')}
                    </Link>
                </button>
                <button
                    className="click mobile_none header_profile_button"
                    onClick={() => toggleMenu('user')}
                >
                    <div className='menu_icon_user'>
                        <img src={user_icon} alt="user_icon" />
                    </div>
                </button>
                <UserModal menuVisible={menuVisibility.user} toggleMenu={() => toggleMenu('user')} />
                <LanguageModal
                    menuVisible={menuVisibility.language}
                    toggleMenu={() => toggleMenu('language')}
                    onSelectLanguage={changeLanguage}
                />
            </div>
            <MenuModal menuVisible={menuVisibility.menu} toggleMenu={() => toggleMenu('menu')} />
            <SearchModal menuVisible={menuVisibility.search} toggleMenu={() => toggleMenu('search')} />
        </header>
    );
}

export default Header;