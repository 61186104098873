import React from 'react';
import "../style/Advertisement.css";
import ProfileAdvertisementsItem from "../items/profile_advertisements_item";

import NoUserAd from "../items/no_user_ad";

import Icon from "../../../common/components/Icon";

import { AdFilterTabButtons } from "../ad/AdFilterTabButtons";
import { AdControlContent } from "../ad/AdControlContent";

import { t } from 'i18next';

export function AdvertisementsUI(handleBackClick, filter, setFilter, counts, searchText, handleSearchChange, categories, sort, handleSortChange, loading, filteredAdsData, getNoUserAdText) {
    return <div className="profile_render_container">
        <div className="menu_right_section_header">
            <Icon src="backicon" onClick={handleBackClick} className={"back_icon_mobile"} />
            <h2 className="menu_right_section_header_text">{t('announcements')}</h2>
            {AdFilterTabButtons(filter, setFilter, counts)}
        </div>
        {AdControlContent(searchText, handleSearchChange, categories, sort, handleSortChange)}
        {loading && <div></div>}
        <ul className="menu_right_section_content ads_list">
            {filteredAdsData.length > 0 ? (
                filteredAdsData.map((ad) => (
                    <ProfileAdvertisementsItem key={ad.id} item={ad} />
                ))
            ) : (
                <NoUserAd {...getNoUserAdText()} />
            )}
        </ul>
    </div>;
}